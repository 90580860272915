






























































































































import { Component, Ref, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { cloneDeep } from "lodash";

import Rules from "@/ab-tests/components/Rules.vue";
import AbTestConfigSettings from "@/ab-tests/components/AbTestConfigSettings.vue";
import AbTestConfigUsersCount from "@/ab-tests/components/AbTestConfigUsersCount.vue";
import AbTestConfigResponseItems from "@/ab-tests/components/AbTestConfigResponseItems.vue";
import SaveConfirmDialog from "@/ab-tests/components/SaveConfirmDialog.vue";
import AbTestConfigurationModel, {
  AbTestMetricType,
  AbTestMinUsersCount,
  AbTestMinUsersCountType,
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import { RuleOperator } from "@/ab-tests/models/RuleModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import { AppSection } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import DateUtil from "@/shared/utils/DateUtil";

@Component({
  components: {
    Rules,
    AbTestConfigSettings,
    AbTestConfigUsersCount,
    AbTestConfigResponseItems,
    SaveConfirmDialog,
  },
})
export default class AbTestConfigurationNewView extends mixins(
  UnsavedChangesMixin
) {
  @Ref("stepForm") readonly stepForm!: Array<VueForm>;

  saveDialog = false;
  abTestConfig = new AbTestConfigurationModel();
  currentStep = 1;
  localAssignmentTillUsersCount = 0;
  usersCountChanged = false;
  steps = [
    {
      name: this.$lang("commonConfig.settings"),
      rules: [ValidUtil.requiredArray(this.$lang("validation.required"))],
      valid: true,
    },
    {
      name: this.$lang("commonConfig.audience"),
      rules: [],
      valid: true,
    },
    {
      name: this.$lang("commonConfig.usersCount"),
      rules: [],
      valid: true,
    },
    {
      name: this.$lang("commonConfig.testGroups"),
      rules: [ValidUtil.requiredArray(this.$lang("validation.required"))],
      valid: true,
    },
  ];

  get previousButtonText(): string {
    return this.currentStep === 1
      ? this.$lang("btn.cancel")
      : this.$lang("btn.back");
  }

  get isShowSaveBtn(): boolean {
    return this.currentStep >= this.steps.length;
  }

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.abTestConfig);
  }

  get existingNames(): Array<string> {
    return this.$store.getters["getAbTestConfigNames"];
  }

  get maxDateInRules() {
    return ConfigUtil.maxDateInRules(this.abTestConfig.rules);
  }

  get maxInstallDate(): string | undefined {
    if (this.abTestConfig.abTestType === AbTestType.AUDIENCE) {
      return DateUtil.twoDaysAgo();
    }

    return this.abTestConfig.activeTill;
  }

  get defaultConfig() {
    return this.$store.state.defaultConfig.config;
  }

  get isConfigCreating(): boolean {
    return this.$store.state.abTestConfig.isConfigCreating;
  }

  get isLoadingConfig(): boolean {
    return this.$store.state.abTestConfig.loadingConfig;
  }

  get isContinueBtnDisabled(): boolean {
    if (this.currentStep === 2) {
      return (
        this.abTestConfig.abTestType === AbTestType.AUDIENCE &&
        !this.abTestConfig.rules.length
      );
    }

    if (this.currentStep === 3) {
      return (
        this.abTestConfig.minUsersCount?.type !==
          AbTestMinUsersCountType.DEFAULT && !this.usersCountChanged
      );
    }

    return false;
  }

  get isBackButtonDisabled(): boolean {
    return this.$store.state.abTestConfig.isMinUsersCountLoading;
  }

  get hasOnlyExcludedRules(): boolean {
    return !!(
      this.abTestConfig.rules.length &&
      this.abTestConfig.rules.length ===
        this.abTestConfig.rules.filter(
          ({ operator }) => operator === RuleOperator.EXCLUDE
        ).length
    );
  }

  @Watch("abTestConfig.rules", { deep: true })
  watchRules() {
    this.resetAbTestMinUsersCount();
    this.steps[1].valid =
      !this.abTestConfig.rules.length ||
      this.abTestConfig.rules.length !==
        this.abTestConfig.rules.filter(
          ({ operator }) => operator === RuleOperator.EXCLUDE
        ).length;
  }

  @Watch("abTestConfig.abTestType")
  watchAbTestType() {
    this.resetAbTestMinUsersCount();

    if (!this.abTestConfig.rules.length) {
      return;
    }

    this.abTestConfig.rules = [];
  }

  @Watch("abTestConfig.metric")
  watchMetric() {
    this.resetAbTestMinUsersCount();
  }

  @Watch("abTestConfig.dayNumber")
  watchDayNumber() {
    this.resetAbTestMinUsersCount();
  }

  created() {
    this.abTestConfig.metric = AbTestMetricType.RETENTION_RATE;
    this.handleWatchingUnsavedChanges();
  }

  beforeDestroy() {
    this.$store.commit("resetMinUsersCount");
    this.$store.commit("closeMinUsersCountEventSource");
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      !this.isSavedForm &&
      this.currentRoutePath === from.path
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleCheckValidation(step: Record<string, any>) {
    if (this.abTestConfig.rules.length) {
      const filteredExcludeRules = this.abTestConfig.rules.filter(
        (rule) => rule.operator === RuleOperator.EXCLUDE
      );

      if (filteredExcludeRules.length === this.abTestConfig.rules.length) {
        step.valid = false;
      } else {
        step.valid = true;
      }
    } else {
      step.valid = true;
    }
  }

  stepComplete(step: number): boolean {
    return this.currentStep > step;
  }

  validate(stepIndex: number) {
    if (stepIndex === 1 && !this.isContinueBtnDisabled) {
      this.currentStep = stepIndex + 2;
      return;
    }

    this.steps[stepIndex].valid = false;
    const isValid = this.stepForm[stepIndex].validate();

    if (isValid || this.currentStep === 2) {
      this.steps[stepIndex].valid = true;
      this.currentStep = stepIndex + 2;
    }
  }

  handleSave() {
    const isValid: boolean = this.stepForm[this.currentStep - 1].validate();

    if (isValid) {
      this.isSavedForm = true;
      this.$store
        .dispatch("createAbTestConfig", this.abTestConfig.getPreparedData())
        .then((response) => {
          this.$router.push({
            name: "abTest_edit_configs",
            params: {
              configId: response?.id,
            },
            query: {
              segmentName: response.responses[0].segmentName,
            },
          });
        });
    }
  }

  handlePrevious() {
    if (this.currentStep === 1) {
      this.$router.push({
        name: AppSection.AB_TESTS,
      });
    } else {
      this.currentStep = this.currentStep - 1;
    }
  }

  handleSaveConfigs() {
    this.handleSave();
    this.saveDialog = false;
  }

  next() {
    if (this.currentStep === 1) {
      this.abTestConfig.parentId = this.defaultConfig.id;
    }

    this.validate(this.currentStep - 1);
  }

  changeLocalAssignmentTillUsersCount(value: number) {
    this.localAssignmentTillUsersCount = value;
  }

  changeControlGroupName(value: string) {
    this.abTestConfig.controlGroupName = value;
  }

  resetAbTestMinUsersCount() {
    if (!this.abTestConfig.minUsersCount) {
      return;
    }

    this.updateMinUsersCount();
  }

  updateMinUsersCount(minUsersCount?: AbTestMinUsersCount) {
    this.abTestConfig.minUsersCount = minUsersCount;
  }
}
